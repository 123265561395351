import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';
import _ from 'lodash';
import {sanitizeText} from 'utils/html';
import Image from 'Components/Image';
import s from './CardArchitectOffice.module.scss';

const CardArchitectOffice = ({title, excerpt, url, label, image, county, logo}) => {
    const {t} = useTranslation();

    const logoUrl = _.get(logo, 'src', '');
    // For third width
    const sizes = [
        '(min-width: 1440px) 411px',
        '(min-width: 1280px) 364px',
        '(min-width: 1024px) 253px',
        '(min-width: 768px) 224px',
        '100vw',
    ];

    const sanitizedTitle = sanitizeText(title);

    const classes = classNames(
        [s['CardArchitectOffice']],
        {[s['CardArchitectOffice--NoImage']]: _.isEmpty(logoUrl) && _.isEmpty(image)},
        {[s['CardArchitectOffice--HasImage']]: !_.isEmpty(logoUrl) || !_.isEmpty(image)},
    );

    /* Add image to link so the title attribute of img is shown on hover */

    return (
        <article className={classes}>

            <a className={s['CardArchitectOffice__Link']} href={url}>
                <span className="sr-only">{title}</span>

                {_.isEmpty(logoUrl) && !_.isEmpty(image) &&
                    <div className={s['CardArchitectOffice__Image']}>
                        <Image {...image} sizes={sizes} useCover={true} />
                    </div>
                }
            </a>

            {_.isEmpty(logoUrl) && !_.isEmpty(image) && // Image placeholder
                <div className={s['CardArchitectOffice__Image']} />
            }

            <div className={s['CardArchitectOffice__Content']}>

                {!_.isEmpty(logoUrl) &&
                    <div className={s['CardArchitectOffice__Logo']}>
                        <Image
                            alt={unescape(sanitizedTitle)}
                            {...logo}
                            sizes={sizes}
                        />
                    </div>
                }

                {label &&
                    <div className={s['CardArchitectOffice__Label']}>{label}</div>
                }

                <h3
                    className={s['CardArchitectOffice__Title']}
                    dangerouslySetInnerHTML={{__html: sanitizedTitle}}
                />

                {excerpt &&
                    <div
                        className={s['CardArchitectOffice__Text']}
                        dangerouslySetInnerHTML={{__html: sanitizeText(excerpt)}}
                    />
                }
            </div>

            {!_.isEmpty(county) &&
                <div className={s['CardArchitectOffice__Footer']}>
                    <div className={s['CardArchitectOffice__County']}>
                        <span className="sr-only">{t('cardArchitectOffice.county')}</span>
                        {county}
                    </div>
                </div>
            }

        </article>
    );
};

CardArchitectOffice.propTypes = {
    title: PropTypes.string.isRequired,
    excerpt: PropTypes.string,
    url: PropTypes.string,
    label: PropTypes.string,
    image: PropTypes.object,
    county: PropTypes.string,
    logo: PropTypes.object,
};

CardArchitectOffice.defaultProps = {
    title: '',
    excerpt: '',
    url: '',
    label: '',
    image: {},
    county: '',
    logo: {},
};

export default CardArchitectOffice;