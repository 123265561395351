import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';
import _ from 'lodash';
import {sanitizeText} from 'utils/html';
import Image from 'Components/Image';
import s from './CardArchitecturePrize.module.scss';

const CardArchitecturePrize = ({
    title,
    year,
    excerpt,
    url,
    label,
    listTag,
    image,
    location,
    isWinner,
}) => {
    const {t} = useTranslation();

    const nomination = isWinner ? t('cardArchitecturePrize.winner') : t('cardArchitecturePrize.nominated');

    // For third width
    const sizes = [
        '(min-width: 1440px) 411px',
        '(min-width: 1280px) 364px',
        '(min-width: 1024px) 253px',
        '(min-width: 768px) 224px',
        '(min-width: 500px) 468px',
        '100vw',
    ];

    const classes = classNames(
        [s['CardArchitecturePrize']],
        {[s['CardArchitecturePrize--NoImage']]: _.isEmpty(image)},
        {[s['CardArchitecturePrize--HasImage']]: !_.isEmpty(image)},
        {[s['CardArchitecturePrize--Winner']]: isWinner},
    );

    /* Add image to link so the title attribute of img is shown on hover */

    return (
        <article className={classes}>

            <a className={s['CardArchitecturePrize__Link']} href={url}>
                <span className="sr-only">{title}</span>

                {!_.isEmpty(image) &&
                    <div className={s['CardArchitecturePrize__Image']}>
                        <Image {...image} sizes={sizes} useCover={true} />
                    </div>
                }
            </a>

            {!_.isEmpty(image) && // Image placeholder
                <div className={s['CardArchitecturePrize__Image']} />
            }

            {isWinner &&
                <div className={s['CardArchitecturePrize__Tab']}>
                    <span>{t('cardArchitecturePrize.winnerYear', {year: year})}</span>
                </div>
            }

            <div className={s['CardArchitecturePrize__Content']}>
                {label &&
                    <div className={s['CardArchitecturePrize__Label']}>{label}</div>
                }

                <h3
                    className={s['CardArchitecturePrize__Title']}
                    dangerouslySetInnerHTML={{__html: sanitizeText(title)}}
                />

                {listTag &&
                    <div className={s['CardArchitecturePrize__Office']}>{listTag}</div>
                }

                {excerpt &&
                    <div
                        className={s['CardArchitecturePrize__Text']}
                        dangerouslySetInnerHTML={{__html: sanitizeText(excerpt)}}
                    />
                }

                {(location || year || nomination) &&
                    <div className={s['CardArchitecturePrize__Footer']}>
                        {location &&
                            <div className={s['CardArchitecturePrize__Location']}>
                                <span className="sr-only">{t('cardArchitecturePrize.county')}:</span>
                                {location}
                            </div>
                        }

                        {year &&
                            <div className={s['CardArchitecturePrize__Date']}>
                                <span className="sr-only">{t('cardArchitecturePrize.date')}</span>
                                <time dateTime={year}>{year}</time>
                            </div>
                        }

                        {nomination &&
                            <div className={s['CardArchitecturePrize__Nomination']}>
                                {nomination}
                            </div>
                        }
                    </div>
                }
            </div>
        </article>
    );
};

CardArchitecturePrize.propTypes = {
    title: PropTypes.string.isRequired,
    year: PropTypes.string,
    excerpt: PropTypes.string,
    url: PropTypes.string,
    label: PropTypes.string,
    listTag: PropTypes.string,
    location: PropTypes.string,
    image: PropTypes.object,
    isWinner: PropTypes.bool,
};

CardArchitecturePrize.defaultProps = {
    title: '',
    year: '',
    excerpt: '',
    url: '',
    label: '',
    listTag: '',
    location: '',
    image: {},
    isWinner: false,
};

export default CardArchitecturePrize;