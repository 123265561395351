import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';
import _ from 'lodash';
import {sanitizeText} from 'utils/html';
import Image from 'Components/Image';
import s from './CardCompetitionEntry.module.scss';

const CardCompetitionEntry = ({title, excerpt, competition, url, label, image}) => {
    const {t} = useTranslation();

    // For third width
    const sizes = [
        '(min-width: 1440px) 411px',
        '(min-width: 1280px) 364px',
        '(min-width: 1024px) 253px',
        '(min-width: 768px) 224px',
        '(min-width: 500px) 468px',
        '100vw',
    ];

    const classes = classNames(
        [s['CardCompetitionEntry']],
        {[s['CardCompetitionEntry--NoImage']]: _.isEmpty(image)},
        {[s['CardCompetitionEntry--HasImage']]: !_.isEmpty(image)},
    );

    /* Add image to link so the title attribute of img is shown on hover */

    return (
        <article className={classes}>

            <a className={s['CardCompetitionEntry__Link']} href={url}>
                <span className="sr-only">{title}</span>

                {!_.isEmpty(image) &&
                    <div className={s['CardCompetitionEntry__Image']}>
                        <Image {...image} sizes={sizes} useCover={true} />
                    </div>
                }
            </a>

            {!_.isEmpty(image) && // Image placeholder
                <div className={s['CardCompetitionEntry__Image']} />
            }

            <div className={s['CardCompetitionEntry__Content']}>

                {label &&
                    <div className={s['CardCompetitionEntry__Label']}>{label}</div>
                }

                <h3
                    className={s['CardCompetitionEntry__Title']}
                    dangerouslySetInnerHTML={{__html: sanitizeText(title)}}
                />

                <div className={s['CardCompetitionEntry__Competition']}>
                    <span className="sr-only">
                        {t('cardCompetitionEntry.competition')}
                    </span>
                    <span
                        dangerouslySetInnerHTML={{__html: sanitizeText(competition)}}
                    />
                </div>

                {excerpt &&
                    <div
                        className={s['CardCompetitionEntry__Text']}
                        dangerouslySetInnerHTML={{__html: sanitizeText(excerpt)}}
                    />
                }
            </div>
        </article>
    );
};

CardCompetitionEntry.propTypes = {
    title: PropTypes.string.isRequired,
    excerpt: PropTypes.string,
    url: PropTypes.string,
    label: PropTypes.string,
    image: PropTypes.object,
    competition: PropTypes.string,
};

CardCompetitionEntry.defaultProps = {
    title: '',
    excerpt: '',
    date: '',
    formattedDate: '',
    url: '',
    label: '',
    image: {},
    competition: '',
};

export default CardCompetitionEntry;