/* global dataLayer */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';
import _ from 'lodash';
import {sanitizeText} from 'utils/html';
import s from './CardCourse.module.scss';

const CardCourse = ({
    label,
    customLabel,
    title,
    customDate,
    date,
    formattedDate,
    location,
    excerpt,
    isExternal,
    url,
    signupText,
    isRegistered,
    isApproved,
    isReserved,
    isFull,
    isFewSeats,
    isOpen,
}) => {
    const {t} = useTranslation();

    const buttonText = isRegistered ? t('cardCourse.buttonText') : (
        !isOpen ? t('cardCourse.closed') : (
            isFull ? t('cardCourse.signupReserve') : (
                !_.isEmpty(signupText) ? signupText : t('cardCourse.signupText')
            )
        )
    );

    const labelText = !_.isEmpty(customLabel) ? customLabel : label;
    const labelClasses = classNames(
        [s['CardCourse__Label']],
        {[s['CardCourse__Label--Highlighted']]: !_.isEmpty(customLabel)},
    );
    const registeredLabel = isApproved ? t('cardCourse.approved') : (
        isReserved ? t('cardCourse.reserved') : t('cardCourse.registered')
    );
    const statusLabel = isOpen && isFewSeats ? t('cardCourse.fewSeats') : (
        isFull ? t('cardCourse.fullyBooked') : (
            !isOpen ? t('cardCourse.closed') : ''
        )
    );

    const clickHandler = () => {
        if(!isRegistered) {
            dataLayer.push({
                event: 'courseRegistrationSteps',
                courseCategory: 'course registration',
                courseAction: 'choice of course',
                courseLabel: title,
                reserve: isFull,
                external: isExternal,
            });
        }
    };

    const classes = classNames(
        [s['CardCourse']],
        {[s['CardCourse--Approved']]: isApproved},
        {[s['CardCourse--Reserved']]: isReserved},
        {[s['CardCourse--Closed']]: !isOpen},
        {[s['CardCourse--Full']]: isFull},
        {[s['CardCourse--FewSeats']]: isFewSeats},
    );

    return (
        <article className={classes}>
            <a className={s['CardCourse__Link']} href={url} onClick={() => clickHandler()}>
                <span className="sr-only">{title}</span>
            </a>
            <div className={s['CardCourse__Content']}>
                {labelText &&
                    <div className={labelClasses}>{labelText}</div>
                }

                <h3
                    className={s['CardCourse__Title']}
                    dangerouslySetInnerHTML={{__html: sanitizeText(title)}}
                />
                <p
                    className={s['CardCourse__Text']}
                    dangerouslySetInnerHTML={{__html: sanitizeText(excerpt)}}
                />
                <footer className={s['CardCourse__Footer']}>

                    {(customDate || (date && formattedDate)) &&
                        <div className={classNames(s['CardCourse__Meta'], s['CardCourse__Meta--Date'])}>
                            <span className="sr-only">{t('cardCourse.date')}:</span>
                            {customDate &&
                                <span>{customDate}</span>
                            }
                            {_.isEmpty(customDate) && date &&
                                <time dateTime={date}>{formattedDate}</time>
                            }
                        </div>
                    }

                    {location &&
                        <div className={classNames(s['CardCourse__Meta'], s['CardCourse__Meta--Location'])}>
                            <span className="sr-only">{t('cardCourse.location')}:</span>
                            {location}
                        </div>
                    }
                </footer>

                {isRegistered &&
                    <div className={s['CardCourse__Registered']}>{registeredLabel}</div>
                }

                {!isRegistered && !_.isEmpty(statusLabel) &&
                    <div className={s['CardCourse__Status']}>{statusLabel}</div>
                }
            </div>

            {!isRegistered && isOpen &&
                <button className={s['CardCourse__Button']} tabIndex="-1">{buttonText}</button>
            }
        </article>
    );
};

CardCourse.propTypes = {
    label: PropTypes.string,
    customLabel: PropTypes.string,
    title: PropTypes.string,
    customDate: PropTypes.string,
    date: PropTypes.string,
    formattedDate: PropTypes.string,
    location: PropTypes.string,
    excerpt: PropTypes.string,
    isExternal: PropTypes.bool,
    url: PropTypes.string,
    signupText: PropTypes.string,
    isRegistered: PropTypes.bool,
    isApproved: PropTypes.bool,
    isReserved: PropTypes.bool,
    isFull: PropTypes.bool,
    isFewSeats: PropTypes.bool,
    isOpen: PropTypes.bool,
};

CardCourse.defaultProps = {
    label: '',
    customLabel: '',
    title: '',
    customDate: '',
    date: '',
    formattedDate: '',
    location: '',
    excerpt: '',
    isExternal: false,
    url: '',
    signupText: '',
    isRegistered: false,
    isApproved: false,
    isReserved: false,
    isFull: false,
    isFewSeats: false,
    isOpen: true,
};

export default CardCourse;
