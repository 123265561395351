import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';
import _ from 'lodash';
import {sanitizeText} from 'utils/html';
import Image from 'Components/Image';
import s from './CardNews.module.scss';

const CardNews = ({
    title,
    excerpt,
    date,
    formattedDate,
    url,
    label,
    image,
    isLarge,
    showTab,
}) => {
    const {t} = useTranslation();

    const sizes = isLarge ? [ // For half width
        '(min-width: 1440px) 628px',
        '(min-width: 1280px) 558px',
        '(min-width: 1024px) 392px',
        '(min-width: 768px) 348px',
        '(min-width: 500px) 468px',
        '100vw',
    ] : [ // For third width
        '(min-width: 1440px) 411px',
        '(min-width: 1280px) 364px',
        '(min-width: 1024px) 253px',
        '(min-width: 768px) 224px',
        '(min-width: 500px) 468px',
        '100vw',
    ];

    const classes = classNames(
        [s['CardNews']],
        {[s['CardNews--NoImage']]: _.isEmpty(image)},
        {[s['CardNews--HasImage']]: !_.isEmpty(image)},
        {[s['CardNews--IsSmall']]: !isLarge},
        {[s['CardNews--IsLarge']]: isLarge},
    );

    /* Add image to link so the title attribute of img is shown on hover */

    return (
        <article className={classes}>

            <a className={s['CardNews__Link']} href={url}>
                <span className="sr-only">{title}</span>

                {!_.isEmpty(image) &&
                    <div className={s['CardNews__Image']}>
                        <Image {...image} sizes={sizes} useCover={true} />
                    </div>
                }
            </a>

            {!_.isEmpty(image) && // Image placeholder
                <div className={s['CardNews__Image']} />
            }

            {showTab &&
                <div className={s['CardNews__Tab']}><span>{t('cardNews.tab')}</span></div>
            }

            <div className={s['CardNews__Content']}>

                {label &&
                    <div className={s['CardNews__Label']}>{label}</div>
                }

                <h3
                    className={s['CardNews__Title']}
                    dangerouslySetInnerHTML={{__html: sanitizeText(title)}}
                />

                {excerpt &&
                    <div
                        className={s['CardNews__Text']}
                        dangerouslySetInnerHTML={{__html: sanitizeText(excerpt)}}
                    />
                }

                <div className={s['CardNews__Date']}>
                    <span className="sr-only">{t('cardNews.date')}</span>
                    <time dateTime={date}>{formattedDate}</time>
                </div>
            </div>
        </article>
    );
};

CardNews.propTypes = {
    title: PropTypes.string.isRequired,
    excerpt: PropTypes.string,
    date: PropTypes.string,
    formattedDate: PropTypes.string,
    url: PropTypes.string,
    label: PropTypes.string,
    image: PropTypes.object,
    isLarge: PropTypes.bool,
    showTab: PropTypes.bool,
};

CardNews.defaultProps = {
    title: '',
    excerpt: '',
    date: '',
    formattedDate: '',
    url: '',
    label: '',
    image: {},
    isLarge: false,
    showTab: false,
};

export default CardNews;
