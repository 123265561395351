import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';
import _ from 'lodash';
import {sanitizeText} from 'utils/html';
import Image from 'Components/Image';
import s from './CardCompetition.module.scss';

const CardCompetition = ({title, excerpt, status, url, label, image}) => {

    // For third width
    const sizes = [
        '(min-width: 1440px) 411px',
        '(min-width: 1280px) 364px',
        '(min-width: 1024px) 253px',
        '(min-width: 768px) 224px',
        '(min-width: 500px) 468px',
        '100vw',
    ];

    const classes = classNames(
        [s['CardCompetition']],
        {[s['CardCompetition--NoImage']]: _.isEmpty(image)},
        {[s['CardCompetition--HasImage']]: !_.isEmpty(image)},
        {[s['CardCompetition--HasStatus']]: !_.isEmpty(status)},
    );

    /* Add image to link so the title attribute of img is shown on hover */

    return (
        <article className={classes}>

            <a className={s['CardCompetition__Link']} href={url}>
                <span className="sr-only">{title}</span>

                {!_.isEmpty(image) &&
                    <div className={s['CardCompetition__Image']}>
                        <Image {...image} sizes={sizes} useCover={true} />
                    </div>
                }
            </a>

            {!_.isEmpty(image) && // Image placeholder
                <div className={s['CardCompetition__Image']} />
            }

            <div className={s['CardCompetition__Content']}>

                {label &&
                    <div className={s['CardCompetition__Label']}>{label}</div>
                }

                <h3
                    className={s['CardCompetition__Title']}
                    dangerouslySetInnerHTML={{__html: sanitizeText(title)}}
                />

                {excerpt &&
                    <div
                        className={s['CardCompetition__Text']}
                        dangerouslySetInnerHTML={{__html: sanitizeText(excerpt)}}
                    />
                }

                {!_.isEmpty(status) &&
                    <Status {...status} />
                }
            </div>
        </article>
    );
};

CardCompetition.propTypes = {
    title: PropTypes.string.isRequired,
    excerpt: PropTypes.string,
    url: PropTypes.string,
    label: PropTypes.string,
    image: PropTypes.object,
    status: PropTypes.object,
};

CardCompetition.defaultProps = {
    title: '',
    excerpt: '',
    date: '',
    formattedDate: '',
    url: '',
    label: '',
    image: {},
    status: {},
};

const Status = ({id, title}) => {
    const {t} = useTranslation();

    const types = {
        0: 'NotStarted',
        1: 'Started',
        2: 'UnderEvaluation',
        3: 'Finished',
    };
    const type = _.get(types, id);
    const classes = classNames(
        [s['CardCompetition__Status']],
        {[s[`CardCompetition__Status--${type}`]]: type},
    );
    return (
        <div className={classes}>
            <span className="sr-only">{t('cardCompetition.status')}</span>
            {title}
        </div>
    );
};

Status.propTypes = {
    id: PropTypes.string,
    title: PropTypes.string,
};

Status.defaultProps = {
    id: '',
    title: '',
};

export default CardCompetition;