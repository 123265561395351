import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';
import _ from 'lodash';
import {sanitizeText} from 'utils/html';
import Image from 'Components/Image';
import s from './CardBlogPost.module.scss';

const CardBlogPost = ({
    label, 
    title,
    excerpt,
    date,
    formattedDate,
    url,
    image,
    author,
    showTab,
}) => {

    const {t} = useTranslation();

    // For third width
    const sizes = [
        '(min-width: 1440px) 411px',
        '(min-width: 1280px) 364px',
        '(min-width: 1024px) 253px',
        '(min-width: 768px) 224px',
        '(min-width: 500px) 468px',
        '100vw',
    ];

    const classes = classNames(
        [s['CardBlogPost']],
        {[s['CardBlogPost--NoImage']]: _.isEmpty(image)},
        {[s['CardBlogPost--HasImage']]: !_.isEmpty(image)},
    );

    return (
        <article className={classes}>
           
           <a className={s['CardBlogPost__Link']} href={url}>
                <span className="sr-only">{title}</span>

                {!_.isEmpty(image) &&
                    <div className={s['CardBlogPost__Image']}>
                        <Image {...image} sizes={sizes} useCover={true} />
                    </div>
                }
            </a>

            {!_.isEmpty(image) && // Image placeholder
                <div className={s['CardBlogPost__Image']} />
            }

            {showTab &&
                <div className={s['CardNews__Tab']}><span>{t('cardBlogPost.tab')}</span></div>
            }

            <div className={s['CardBlogPost__Content']}>
                
                <div className={s['CardBlogPost__Header']}>
                    
                    {label &&
                        <div className={s['CardBlogPost__Label']}>{label}</div>
                    }

                    <div className={s['CardBlogPost__Date']}>
                        <span className="sr-only">{t('cardBlogPost.date')}</span>
                        <time dateTime={date}>{formattedDate}</time>
                    </div>

                </div>

                <h3
                    className={s['CardBlogPost__Title']}
                    dangerouslySetInnerHTML={{__html: sanitizeText(title)}}
                />

                {excerpt &&
                    <div
                        className={s['CardBlogPost__Text']}
                        dangerouslySetInnerHTML={{__html: sanitizeText(excerpt)}}
                    />
                }

                {!_.isEmpty(author) &&
                    <Author {...author} />
                }

            </div>
        </article>
    );
};

CardBlogPost.propTypes = {
    label: PropTypes.string,
    title: PropTypes.string,
    excerpt: PropTypes.string,
    date: PropTypes.string,
    formattedDate: PropTypes.string,
    url: PropTypes.string,
    image: PropTypes.object,
    author: PropTypes.object,
    showTab: PropTypes.bool,
};

CardBlogPost.defaultProps = {
    label: '',
    title: '',
    excerpt: '',
    date: '',
    formattedDate: '',
    url: '',
    image: {},
    author: {},
    showTab: false,
};

const Author = ({title, image}) => {
    const {t} = useTranslation();

    // For third width
    const sizes = [
        '44px',
    ];

    return (
        <div className={s['CardBlogPost__Author']}>
            <span className="sr-only">{t('cardBlogPost.author')}</span>
            {!_.isEmpty(image) &&
                <div className={s['CardBlogPost__AuthorImage']}>
                    <Image {...image} sizes={sizes} useCover={true} />
                </div>
            }

            {title &&
                <span
                    className={s['CardBlogPost__AuthorName']}
                    dangerouslySetInnerHTML={{__html: sanitizeText(title)}}
                />
            }
        </div>
    );
};

Author.propTypes = {
    title: PropTypes.string,
    image: PropTypes.object,
};

Author.defaultProps = {
    title: '',
    image: {},
};

export default CardBlogPost;
