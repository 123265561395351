import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import {sanitizeText} from 'utils/html';
import Image from 'Components/Image';
import s from './CardPage.module.scss';

const CardPage = ({
    title,
    excerpt,
    url,
    label,
    image,
    showImage,
}) => {
    const hasImage = showImage && !_.isEmpty(image);

    // For third width
    const sizes = [
        '(min-width: 1440px) 411px',
        '(min-width: 1280px) 364px',
        '(min-width: 1024px) 253px',
        '(min-width: 768px) 224px',
        '(min-width: 500px) 468px',
        '100vw',
    ];

    const classes = classNames(
        [s['CardPage']],
        {[s['CardPage--NoImage']]: !hasImage},
        {[s['CardPage--HasImage']]: hasImage},
    );

    /* Add image to link so the title attribute of img is shown on hover */

    return (
        <article className={classes}>

            <a className={s['CardPage__Link']} href={url}>
                <span className="sr-only">{title}</span>

                {hasImage &&
                    <div className={s['CardPage__Image']}>
                        <Image {...image} sizes={sizes} useCover={true} />
                    </div>
                }
            </a>

            {!_.isEmpty(image) && // Image placeholder
                <div className={s['CardPage__Image']} />
            }

            <div className={s['CardPage__Content']}>

                {label &&
                    <div className={s['CardPage__Label']}>{label}</div>
                }

                <h3
                    className={s['CardPage__Title']}
                    dangerouslySetInnerHTML={{__html: sanitizeText(title)}}
                />

                {excerpt &&
                    <div
                        className={s['CardPage__Text']}
                        dangerouslySetInnerHTML={{__html: sanitizeText(excerpt)}}
                    />
                }
            </div>
        </article>
    );
};

CardPage.propTypes = {
    title: PropTypes.string.isRequired,
    excerpt: PropTypes.string,
    url: PropTypes.string,
    label: PropTypes.string,
    image: PropTypes.object,
    showImage: PropTypes.bool,
};

CardPage.defaultProps = {
    title: '',
    excerpt: '',
    url: '',
    label: '',
    image: {},
    showImage: false,
};

export default CardPage;
